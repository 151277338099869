import { Controller } from "@hotwired/stimulus";
import gsap from 'gsap';
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["submenu", "menuBarItems", "searchbar", "searchbarMobile", "searchButtonOpen", "searchButtonClose"];
  isSearchBarActive = false;

  connect() {
    this.handleOutsideSearchBarClick = this.handleOutsideSearchBarClick.bind(this);
    this.handleSearchbarKeydown = this.handleSearchbarKeydown.bind(this);
    document.addEventListener('keydown', this.handleSearchbarKeydown);
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleSearchbarKeydown);
  }

  selectMenuItem(event) {
    const button = event.currentTarget;
    const isOpen = button.getAttribute('aria-expanded') === 'true';
    this._selectMenuItem(button, !isOpen);
  }

  handleFocus(event) {
    const button = event.currentTarget;
    const isClosed = button.getAttribute('aria-expanded') === 'false';
    if (isClosed) this.toggleCollapseDebounced(null, this.submenuTargets);
  }

  handleMouseEnter(event) {
    const button = event.currentTarget.querySelector('button');
    this._selectMenuItem(button, true);
  }

  handleMouseLeave() {
    this.toggleCollapseDebounced(null, this.submenuTargets);
  }

  toggleCollapseDebounced = debounce(this._toggleCollapse, 50);


  enableSearchBar() {
    this._toggleSearchBar(true);
  }

  disableSearchBar() {
    this._toggleSearchBar(false);
  }

  toggleMenu(event) {
    this._toggleMenu(event.currentTarget);
  }

  _toggleMenu(button) {
    const target = button.getAttribute('aria-controls');
    const menu = this.element.querySelector(`#${target}`);
    document.body.classList.toggle('overflow-hidden');
    document.body.classList.toggle('mobile-menu-open');

    const isVisible = menu.classList.contains('show');
    button.setAttribute('aria-expanded', !isVisible ? 'true' : 'false');
    if (!isVisible) menu.classList.add('show', 'open');
    const height = window.innerHeight - menu.getBoundingClientRect().y;
    gsap.to(menu,  {
      height: isVisible ? 0 : height,
      duration: 0.3,
      onComplete: () => {
        if (isVisible) menu.classList.remove('show', 'open');
      }
    });
  }

  _toggleSearchBar(enable) {
    this.isSearchBarActive = enable;
    const searchBar = window.innerWidth > 1024 ? this.searchbarTarget : this.searchbarMobileTarget;
    this.element.classList.toggle("searchbar-active", enable);
    this.searchButtonOpenTarget.classList.toggle("disabled", enable);
    this.searchButtonCloseTarget.classList.toggle("disabled", !enable);
    searchBar.classList.toggle("hidden", !enable);
    const menuTransform = { opacity: enable ? 0 : 1, duration: 0.3 };
    if (window.innerWidth > 1024) menuTransform.width = enable ? 0 : 'auto';
    gsap.to(this.menuBarItemsTarget, menuTransform);
    gsap.to(searchBar, {
      // display: enable ? 'block' : 'none',
      opacity: enable ? 1 : 0,
      width: enable ? '100%' : 0,
      duration: 0.3,
      onComplete: () => {
        if (enable) document.dispatchEvent(new CustomEvent('searchbarActivated', {bubbles: true}));
        this._listenForClickOutsideSearchBar(enable);
      }
    });
  }

  _selectMenuItem(button, enable) {
    const targetId = button.getAttribute('aria-controls');
    const target = this.element.querySelector(`#${targetId}`);
    this.toggleCollapseDebounced(target, this.submenuTargets);
  }

  _toggleCollapse(target, otherItems) {
    const shouldCloseMobile = target?.classList.contains('show') && window.innerWidth < 1024;
    let closeDuration = target ? 0 : 0.3;
    if (window.innerWidth < 1024) closeDuration = 0.3;

    let currentActiveItemHeight = 0;
    otherItems?.forEach((el) => {
      if (el.clientHeight > currentActiveItemHeight) currentActiveItemHeight = el.clientHeight;
      if (window.innerWidth > 1024 && (el === target || !el.classList.contains('show'))) return;
      if (!shouldCloseMobile && (el === target || !el.classList.contains('show'))) return;
      const controlId = el.getAttribute('aria-labelledby');
      const control = this.element.querySelector(`#${controlId}`);
      control.setAttribute('aria-expanded', 'false');
      gsap.to(el, { height: 0, duration: closeDuration, onComplete: () => {
        el.classList.remove('show');
        el.removeAttribute('style');
      }});
    });

    if (target && !shouldCloseMobile) {
      const controlId = target.getAttribute('aria-labelledby');
      const control = this.element.querySelector(`#${controlId}`);
      control?.setAttribute('aria-expanded', 'true');
      target.classList.add('show');
      gsap.fromTo(target, {height: currentActiveItemHeight}, {height: 'auto', duration: 0.3});

      if (currentActiveItemHeight) return;

      const subMenuItems = target.querySelectorAll('.sub-menu-item, .card');
      if (subMenuItems.length) {
        const hasCard = target.querySelector('.card');
        let columnCount = 1;
        if (window.innerWidth > 1024) columnCount = hasCard ? 2 : 3;

        const grid = [Math.ceil(subMenuItems.length / columnCount), columnCount];
        gsap.fromTo(subMenuItems,
          { y: -5, opacity: 0 },
          { y: 0, opacity: 1, duration: 0.3, delay: 0.2, stagger: { amount: 0.16, grid: grid, axis: null, from: 0 } }
        );
      }
    }
  }

  handleOutsideSearchBarClick(event) {
    const isSearchBar = this.element.contains(event.target);
    if (!isSearchBar) this.disableSearchBar();
  }

  handleSearchbarKeydown(event) {
    if (event.key === 'k' && event.metaKey) {
      this.enableSearchBar();
    } else if (this.isSearchBarActive && ['Escape', 'Esc'].includes(event.key)) {
      this.disableSearchBar();
    }
  }

  _listenForClickOutsideSearchBar(enable) {
    if (enable) {
      document.addEventListener('click', this.handleOutsideSearchBarClick);
    } else {
      document.removeEventListener('click', this.handleOutsideSearchBarClick);
    }
  }
}
