function handleControlVariantDomMutations() {
  const signUpForMoneybird = document.querySelector("[data-controller='sign-up-for-moneybird']");
  signUpForMoneybird.classList.add("control");
}

function handleDefaultVariantDomMutations() {
  const signUpForMoneybird = document.querySelector("[data-controller='sign-up-for-moneybird']");
  signUpForMoneybird.classList.remove("control");
  signUpForMoneybird.classList.add("variant");

  const headerWrapper = document.querySelector("header .lg\\:w-\\[52\\%\\]");
  if (headerWrapper){
    headerWrapper.classList.remove('lg:w-[52%]');
    headerWrapper.classList.add('pb-4');
    headerWrapper.classList.remove('pb-16');
  }

  // remove the label add the top of the page
  const label = document.querySelector("header .label");
  if (label) label.remove();

  const pageTitle = document.querySelector("#page-title");
  if (pageTitle) pageTitle.textContent = "Je volledige boekhouding en zakelijke rekening, 6 maanden gratis.";

  document.querySelector("header .intro-text")?.remove();
}

function localizeNumbers() {
  const numbers = document.querySelectorAll(".local-number");
  numbers.forEach((number) => {
    number.textContent = Number(number.textContent).toLocaleString();
  });
}

export default function(gb) {
  const feature = gb.evalFeature("convincing-signup-page");
  // only on the signup page
  if (!['/aanmelden/', '/nl/aanmelden/'].includes(window.pageProperties?.relative_url)) return;
  if (!['nl', 'test_lang'].includes(window.pageProperties?.locale)) return;

  localizeNumbers();

  const signupButton = document.querySelector("form button[type='submit']");
  if (signupButton) {
    signupButton.addEventListener("click", () => {
      trackEvent({
        exp_event_cat: "ab006-convincing-signup-page",
        exp_event_act: "submit-signup-form",
      });
    });
  }

  if (feature.on && feature.value === 1) {
    handleDefaultVariantDomMutations();
  } else {
    handleControlVariantDomMutations();
  }
}