function triggerClarity({ experiment, variation }) {
  try {
    if (!experiment?.key || !variation?.key) return;

    window.clarity = window.clarity || function () {
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };

    window.clarity("set", "Experiment", `${experiment.key} - ${experiment.name}`);
    window.clarity("set", "Variation", `${variation.key} - ${variation.name}`);
  } catch (error) {
    console.error(error);
  }
}

export default triggerClarity;
