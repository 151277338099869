import { Controller } from '@hotwired/stimulus'
import Prism from 'prismjs'
import gsap from 'gsap';
import Flip from "gsap/Flip";

gsap.registerPlugin(Flip);

// Laad de talen die je wilt ondersteunen
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-bash' // Voor cURL commands
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-css'

import 'prismjs/plugins/line-numbers/prism-line-numbers.js'

export default class extends Controller {
  static targets = ['tab', 'codeBlock', 'backdrop']

  connect() {
    const savedLanguage = localStorage.getItem('preferredCodeLanguage') || 'curl'
    this.availableLanguages = this.tabTargets.map(tab => tab.dataset.language)

    this.switchToLanguage(savedLanguage)

    document.addEventListener('code-language-changed', (event) => {
      if (event.detail.source === this.element) return;
      if (!this.availableLanguages.includes(event.detail.language)) return;

      this.switchToLanguage(event.detail.language);
    })

    Prism.highlightAll();
  }

  switchTab(event) {
    const language = event.currentTarget.dataset.language
    this.switchToLanguage(language)

    localStorage.setItem('preferredCodeLanguage', language)

    document.dispatchEvent(new CustomEvent('code-language-changed', {
      detail: {
        language,
        source: this.element
      }
    }))
  }

  switchToLanguage(language) {
    const state = Flip.getState(this.backdropTarget);

    // Find if the requested language exists in tabs
    const languageExists = this.availableLanguages.includes(language)

    // If language doesn't exist, use the first available language
    const targetLanguage = languageExists ? language : this.tabTargets[0]?.dataset.language

    this.tabTargets.forEach(tab => {
      const isActive = tab.dataset.language === targetLanguage
      tab.setAttribute('aria-selected', isActive)
      tab.setAttribute('role', 'tab')

      if (isActive) {
        // move backdrop element inside this button
        tab.appendChild(this.backdropTarget);
      }
    })

    this.codeBlockTargets.forEach(block => {
      const isActive = block.dataset.language === targetLanguage
      block.setAttribute('aria-hidden', !isActive)
      block.classList.toggle('hidden', !isActive)
    })

    Flip.from(state, {
      absolute: true,
      absoluteOnLeave: true,
      duration: 0.3,
      ease: "power1.inOut",
      onEnter: elements => gsap.fromTo(elements, {opacity: 0, x: -20 * direction}, {opacity: 1, x: 0, duration: 0.4, ease: "power1.inOut" }),
      onLeave: elements => gsap.to(elements, {opacity: 0, x: 20 * direction, duration: 0.4, ease: "power1.inOut" }),
    });
  }
}